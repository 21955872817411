<template>
  <tr class="border-none">
    <td
      class="px-5 py-5 border-b border-gray-200 text-sm w-2/5 bg-beige"
    >
      <div class="flex items-center text-center">
        <div class="flex-shrink-0 w-10 h-10">
          <img class="w-8 h-8 rounded-full" :src="student.img_url? student.img_url : filler_img" alt="" />
        </div>
        <div class="w-24">
          <p
            class="
              text-gray-900
              whitespace-nowrap
              overflow-ellipsis overflow-hidden
              truncate
              capitalize
            "
          >
            {{ student.name }}
          </p>
        </div>
      </div>
    </td>
    <td class="px-5 py-5 border-b border-gray-200 text-sm bg-beige">
      <p class="text-gray-900 whitespace-nowrap text-center">
        {{ student.classOf }}
      </p>
    </td>
    <td
      class="px-5 py-5 border-b border-gray-200 text-sm w-2/5 bg-beige"
    >
      <p class="text-gray-900 whitespace-no-wrap text-center">
        {{ num_sessions }}
      </p>
    </td>
  </tr>
</template>

<script>
import { mapState } from "vuex"
export default {
  props: {
    student: Object,
    num_sessions: Number
  },
  computed: {
    ...mapState(['filler_img'])
  },
};
</script>