<template>
  <n-card class="bg-beige border-none shadow-lg my-3 hover:shadow max-w-md rounded-2xl">
    <n-text strong style="font-size: 24px"> &ldquo; </n-text>
    <div style="margin: -20px 20px">
      <n-text>
        {{ review.text }}
      </n-text>
      <n-text strong style="float: right; font-size: 24px"> &rdquo; </n-text>
    </div>

    <n-divider></n-divider>
    <div style="display: inline-flex">
      <n-avatar round size="medium" :src="review.student.img_url?review.student.img_url:filler_img" />
      <div style="margin-left: 20px">
        <n-h4 style="margin-bottom: -2px"
          ><n-text type="primary" class="capitalize">{{ review.student.name }}</n-text></n-h4
        >
        <n-text depth="3">{{ review.student.classOf }}</n-text>
      </div>
    </div>
  </n-card>
</template>

<script>
import { NCard, NText, NAvatar, NDivider, NH4 } from "naive-ui";
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState(['filler_img'])
  },
  components: {
    NCard,
    NText,
    NAvatar,
    NDivider,
    NH4,
  },
  props: {
    review: Object,
  },
};
</script>