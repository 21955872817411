<template>
  <div class="container mx-auto px-4 sm:px-8">
    <div class="py-8">
      <div>
        <h2 class="text-xl font-semibold leading-tight">students</h2>
      </div>
      <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
          <table class="min-w-full leading-normal">
            <thead>
              <tr>
                <th
                  class="
                    px-5
                    py-3
                    border-b-2 border-orange
                    text-xs
                    font-semibold
                    text-orange
                    tracking-wider
                    bg-orange-tint
                  "
                >
                  student
                </th>
                <th
                  class="
                    px-5
                    py-3
                    border-b-2 border-orange
                    text-xs
                    font-semibold
                    text-orange
                    tracking-wider
                    bg-orange-tint
                  "
                >
                  class of
                </th>
                <th
                  class="
                    px-1
                    py-3
                    border-b-2 border-orange
                    text-xs
                    font-semibold
                    text-orange
                    tracking-wider
                    bg-orange-tint
                  "
                >
                  no. sessions
                </th>
              </tr>
            </thead>
            <tbody>
              <student-row
                v-for="(student, index) in course.students"
                :key="index"
                :student="student"
                :num_sessions="num_sessions(student)"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StudentRow from "./StudentRow.vue";
export default {
  components: { StudentRow },
  props: {
    course: Object,
  },
  data() {
    return {
      students: this.course.students,
    };
  },
  methods: {
    num_sessions(st) {
      let num = 0;
      let included = [];
      for (let i = 0; i < st.sessions.length; i++)
        if (
          this.course.sessions.includes(st.sessions[i]) &&
          !included.includes(st.sessions[i])
        ) {
          num++;
          included.push(st.sessions[i]);
        }
      return num;
    },
  },
};
</script>