<template>
  <page-layout
    v-if="course_previewed"
    :title="`${course_previewed.class.name}`"
  >
    <p class="text-orange font-bold text-2xl float-right mx-4">
      {{ Math.round(course_previewed.rating * 10) / 10
      }}<span class="fa fa-star text-lg text-orange"> </span>
    </p>
    <p class="text-2xl mx-4 mt-8">
      <span class="font-bold">{{ course_previewed.sessions.length }}</span>
      sessions
    </p>
    <p class="font-semibold text-xl mx-4 my-4">recent reviews</p>
    <div class="mx-4 my-4">
      <transition-group tag="ul" name="scale-up" appear class="relative">
        <li v-for="(review, index) in course_previewed.reviews" :key="index">
          <tutor-review :review="review" />
        </li>
      </transition-group>
    </div>
    <class-students :course="course_previewed" />
  </page-layout>
</template>

<script>
import PageLayout from "../components/base/pageLayout.vue";
import ClassStudents from "../components/ClassStudents.vue";
import TutorReview from "../components/TutorReview.vue";
import { useRoute, useRouter } from "vue-router";

import { mapActions, mapState } from "vuex";
export default {
  components: { ClassStudents, TutorReview, PageLayout },
  computed: {
    ...mapState(["previewed_tutors", "course_previewed"]),
  },
  created() {
    this.get_course(this.$route.params.id);
  },
  methods: {
    ...mapActions(["get_course"]),
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { course_id } = route.params;
    return { course_id, router };
  },
};
</script>
